import axios from "axios";
import {API_URL} from "./urls";

export const string_to_slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes

    return str;
};

export async function postToDB(user, endpoint, data, auth = true) {
    let headers = {};
    if (auth) {
        headers = {'Authorization': 'Bearer '+user.jwt};
    }
    const response = await axios.post(API_URL+'/'+endpoint, data, {
        headers: headers
    });
    //console.log(response);
    return response.data;
}

export async function getFromDB(user, endpoint, data) {
    const response = await axios.get(API_URL+'/'+endpoint, {
        headers: {'Authorization': 'Bearer '+user.jwt},
        params:data
    });
    //console.log(response);
    return response.data;
}