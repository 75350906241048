import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Form from "react-bootstrap/Form";

import '../PlayControls.scss';

function PlayControls(props) {
    const audioPlayer = useRef(null);
    const playPause = useRef(null);
    const [btnPlayPause, setBtnPlayPause] = useState(null);
    const [userInteraction,setUserInteraction] = useState(false);
    const [isMetronomeSoundOn,setIsMetronomeSoundOn] = useState(props.isTempoMatched);
    const [showVolumeControls, _setShowVolumeControls] = useState(false);
    const showVolumeControlsRef = useRef(showVolumeControls);
    const setShowVolumeControls = data => {
        showVolumeControlsRef.current = data;
        _setShowVolumeControls(data);
    };
    const [showMetronomeControls, _setShowMetronomeControls] = useState(false);
    const showMetronomeControlsRef = useRef(showMetronomeControls);
    const setShowMetronomeControls = data => {
        showMetronomeControlsRef.current = data;
        _setShowMetronomeControls(data);
    };
    const loading = useRef(null);
    const progress = useRef(null);
    const sliders = node => {};
    const volumeBtn = useRef(null);
    const metronomeBtn = useRef(null);
    const volumeControls = useRef(null);
    const volumeProgress = useRef(null);
    const metronomeControls = useRef(null);
    const metronomeProgress = useRef(null);
    const currentTime = useRef(null);
    const speaker = useRef(null);
    const metronome = useRef(null);

    let draggableClasses = ['pin'];
    let currentlyDragged = null;

    useEffect(() => {
               
        document.addEventListener("mousedown", handleButtonClick);

        window.addEventListener('resize', directionAware);

        directionAware();

        attachPlayerEventHandlers();

        return function cleanup() {
            document.removeEventListener("mousedown", handleButtonClick);
        };
    }, []);
    
    useEffect(() => {
        if (props.isTempoMatched != null) {
            setIsMetronomeSoundOn(props.isTempoMatched);
            //props.midiTrack.setMetronomeVolume((props.isTempoMatched) ? 1 : 0);
        }
        
    },[props.isTempoMatched]);
    
    function handleButtonClick(event) {
        if (!showVolumeControlsRef.current && (volumeBtn.current.contains(event.target)) || (showVolumeControlsRef.current && volumeControls.current.contains(event.target))) {
            // inside click
            setShowVolumeControls(true);
            return;
        } else if (showVolumeControlsRef.current && (volumeBtn.current.contains(event.target))) {
            setShowVolumeControls(false);
        }
        
        if (!showMetronomeControlsRef.current && (metronomeBtn.current.contains(event.target)) || (showMetronomeControlsRef.current && metronomeControls.current.contains(event.target))) {
            // inside click
            setShowMetronomeControls(true);
            return;
        } else if (showVolumeControlsRef.current && (volumeBtn.current.contains(event.target))) {
            setShowMetronomeControls(false);
        }
        
        // outside click
        setShowMetronomeControls(false);
        setShowVolumeControls(false);
    }

    function attachPlayerEventHandlers() {
        props.setCallables({
            onEvent: (event) => {
                
            },
            onProgress: updateProgress
        });
    }
    
    function isDraggable(el) {
        let canDrag = false;
        let classes = Array.from(el.classList);
        draggableClasses.forEach(draggable => {
            if(classes.indexOf(draggable) !== -1)
                canDrag = true;
        });
        return canDrag;
    }
    
    function handleSliderMove(event) {
        if(!isDraggable(event.target)) return false;

        currentlyDragged = event.target;
        let handleMethodString = currentlyDragged.dataset.method;
        let handleMethod = null;
        if (handleMethodString=="changeVolume") {
            handleMethod = changeVolume;
        } else if (handleMethodString=="changeTempo") {
            handleMethod = changeTempo;
        } else if (handleMethodString == "rewind") {
            handleMethod = rewind;
        } else {
            handleMethod = rewind;
        }
        
        window.addEventListener('mousemove', handleMethod, false);

        window.addEventListener('mouseup', () => {
            currentlyDragged = false;
            window.removeEventListener('mousemove', handleMethod, false);
        }, false);
    }

    function inRange(event) {
        let rangeBox = getRangeBox(event);
        let rect = rangeBox.getBoundingClientRect();
        let direction = rangeBox.dataset.direction;
        if(direction == 'horizontal') {
            var min = rangeBox.offsetLeft;
            var max = min + rangeBox.offsetWidth;
            if(event.clientX < min || event.clientX > max) return false;
        } else {
            var min = rect.top;
            var max = min + rangeBox.offsetHeight;
            if(event.clientY < min || event.clientY > max) return false;
        }
        return true;
    }

    function updateProgress(midiTrack) {
        if (midiTrack != null) {
            var percent = midiTrack.percentage * 100;
            progress.current.style.width = percent + '%';

            //currentTime.current.textContent = formatTime(current);

            const regex = /^[0-9]*:[0-9]*:[0-9]*/;
            currentTime.current.textContent = midiTrack.barsAsString.match(regex);
            let position = midiTrack.getPosition('percentage',percent/100);
            let finalPosition = midiTrack.getPosition('percentage',100);
            //console.log(position);
            //console.log(props.midiTrack.millis);
            //  totalTime.current.textContent = (finalPosition.millis - position.millis)/1000;
        }
    }

    // function updateVolume() {
    //     if (player != null) {
    //         volumeProgress.current.style.height = player.volume * 100 + '%';
    //         if(player.volume >= 0.5) {
    //             speaker.current.attributes.d.value = 'M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z';
    //         } else if(player.volume < 0.5 && player.volume > 0.05) {
    //             speaker.current.attributes.d.value = 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667M17.333 11.373C17.333 9.013 16 6.987 14 6v10.707c2-.947 3.333-2.987 3.333-5.334z';
    //         } else if(player.volume <= 0.05) {
    //             speaker.current.attributes.d.value = 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667';
    //         }
    //     }
    // }

    function getRangeBox(event) {
        let rangeBox = event.target;
        let el = currentlyDragged;
        if(event.type == 'click' && isDraggable(event.target)) {
            rangeBox = event.target.parentElement.parentElement;
        }
        if(event.type == 'mousemove') {
            rangeBox = el.parentElement.parentElement;
        }
        return rangeBox;
    }

    function getCoefficient(event) {
        let slider = getRangeBox(event);
        let rect = slider.getBoundingClientRect();
        let K = 0;
        if(slider.dataset.direction == 'horizontal') {

            let offsetX = event.clientX - slider.offsetLeft;
            let width = slider.clientWidth;
            K = offsetX / width;

        } else if(slider.dataset.direction == 'vertical') {

            let height = slider.clientHeight;
            var offsetY = event.clientY - rect.top;
            K = 1 - offsetY / height;

        }
        return K;
    }

    function rewind(event) {
        if(inRange(event)) {
            if (props.eventHandler.length > 0) {
                props.eventHandler.forEach(handler => {
                    if (handler.onResetPiano != null) {
                        handler.onResetPiano();
                    }
                });
            }
            const wasPlaying = props.midiTrack.playing;
            pausePlayer();
            //player.currentTime = player.duration * getCoefficient(event);
            let position = props.midiTrack.getPosition('percentage', getCoefficient(event));
            props.midiTrack.setPlayhead('millis', position.millis);
            props.midiTrack.pause();
            updateProgress();
            //if (wasPlaying)
                //togglePlay();
        }
    }
    
    function pausePlayer() {
        props.midiTrack.pause();
    }

    function changeVolume(event) {
        if(inRange(event)) {
            let volume = getCoefficient(event);
            props.midiTrack.tracks.forEach(track => {
                track.setVolume(volume);
            });
            volumeProgress.current.style.height = volume * 100 + '%';
            if(volume >= 0.5) {
                speaker.current.attributes.d.value = 'M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z';
            } else if(volume < 0.5 && volume > 0.05) {
                speaker.current.attributes.d.value = 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667M17.333 11.373C17.333 9.013 16 6.987 14 6v10.707c2-.947 3.333-2.987 3.333-5.334z';
            } else if(volume <= 0.05) {
                speaker.current.attributes.d.value = 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667';
            }
        }
    }
    
    function changeTempo(event) {
        if (inRange(event)) {
            let tempo = getCoefficient(event);
            props.midiTrack.setTempo(tempo*100);
            //console.log("changed tempo to: "+tempo*100+" BPM");
            metronomeProgress.current.style.height = tempo * 100 + '%';
        }
    }

    function formatTime(time) {
        var min = Math.floor(time / 60);
        var sec = Math.floor(time % 60);
        return min + ':' + ((sec<10) ? ('0' + sec) : sec);
    }

    function togglePlay() {
        if (props.midiTrack != null) {
            props.midiTrack.setMetronomeVolume((isMetronomeSoundOn) ? 1 : 0);
            if(!props.midiTrack.playing) {
                props.midiTrack.play();
            } else {
                pausePlayer();
            }
        }
    }
    
    useEffect(() => {
        if (isMetronomeSoundOn != null && props.midiTrack != null) {
            props.midiTrack.setMetronomeVolume((isMetronomeSoundOn) ? 1 : 0);
        }
    }, [isMetronomeSoundOn]);
    
    function toggleMetronomeSound() {
        let newValue = !isMetronomeSoundOn;
        setIsMetronomeSoundOn(newValue);
        // set player metronome volume
    }

    function directionAware() {
        // if(window.innerHeight < 250) {
        //     volumeControls.current.style.bottom = '-54px';
        //     volumeControls.current.style.left = '54px';
        //     metronomeControls.current.style.bottom = '-54px';
        //     metronomeControls.current.style.left = '54px';
        // } else if(volumeControls.current.offsetTop < 154) {
        //     volumeControls.current.style.bottom = '-164px';
        //     volumeControls.current.style.left = '-3px';
        //     metronomeControls.current.style.bottom = '-164px';
        //     metronomeControls.current.style.left = '-3px';
        // } else {
        //     volumeControls.current.style.bottom = '52px';
        //     volumeControls.current.style.left = '-3px';
        //     metronomeControls.current.style.bottom = '52px';
        //     metronomeControls.current.style.left = '-3px';
        // }
    }
    
    useEffect(() => {
        if (props.midiTrack == null) {
            setBtnPlayPause(<div className="loading">
                <div className="spinner"></div>
            </div>);
        } else if (props.isPlaying) {
            setBtnPlayPause(<div style={{display:"block"}} className="play-pause-btn" onClick={togglePlay}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                    <path fill="#566574" fillRule="evenodd" d="M0 0h6v24H0zM12 0h6v24h-6z" className="play-pause-icon"
                          id="playPause" ref={playPause} />
                </svg>
            </div>);
        } else {
            setBtnPlayPause(<div style={{display:"block"}} className="play-pause-btn" onClick={togglePlay}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                    <path fill="#566574" fillRule="evenodd" d="M18 12L0 24V0" className="play-pause-icon"
                          id="playPause" ref={playPause} />
                </svg>
            </div>);
        }
    }, [props.midiTrack, props.isPlaying]);
    
    return (
        <div>
            <div className="holder" onMouseDown={handleSliderMove}>
                <div className="audio green-audio-player" ref={audioPlayer}>
                    
                    {btnPlayPause}

                    <div className="controls">
                        <span className="current-time" ref={currentTime}>0:0:0</span>
                        <div className="slider" data-direction="horizontal" ref={sliders}>
                            <div className="progress" ref={progress}>
                                <div className="pin" id="progress-pin" data-method="rewind"></div>
                            </div>
                        </div>
                        {/*<span className="total-time" ref={totalTime}>0:0:0</span>*/}
                    </div>

                    <div className="volume">
                        <div className="volume-btn" ref={volumeBtn}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#566574" fillRule="evenodd"
                                      d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z"
                                      id="speaker" ref={speaker}/>
                            </svg>
                        </div>
                        {showVolumeControls &&
                        <div className="volume-controls" ref={volumeControls}>
                            <div className="slider" data-direction="vertical" ref={sliders}>
                                <div className="progress" ref={volumeProgress}>
                                    <div className="pin" id="volume-pin" data-method="changeVolume" onClick={changeVolume}></div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    
                    <div className={"metronome"}>
                        <div className={"metronome-btn"} ref={metronomeBtn} style={{
                            //overflow: 'hidden',
                            width: '24px',
                            height: 'auto',
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.999 511.999" width="100%">
                                <g xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#566574" fillRule="evenodd" d="m473.043 67.742c-6.362-5.302-15.821-4.444-21.125 1.92l-15.641 18.766c-4.305-2.167-9.092-3.327-13.977-3.327-9.228 0-17.908 4.038-23.818 11.079l-10.327 12.31c-9.499 11.323-9.514 27.489-.913 38.783l-34.449 41.339-32.16-160.798c-3.118-15.597-18.02-27.814-33.926-27.814h-128.535c-15.906 0-30.807 12.218-33.924 27.813l-65.131 325.653 148.322.001v-85.68h-17.133c-8.283 0-15-6.716-15-15s6.717-15 15-15h17.133v-27.841h-17.133c-8.283 0-15-6.716-15-15s6.717-15 15-15h17.133v-27.84h-17.131c-8.285 0-15-6.716-15-15s6.715-15 15-15h17.131v-27.84h-17.132c-8.284 0-15-6.716-15-15s6.716-15 15-15h64.267c8.284 0 15 6.716 15 15s-6.716 15-15 15h-17.135v27.841h17.135c8.285 0 15 6.716 15 15s-6.715 15-15 15h-17.135v27.84h17.135c8.283 0 15 6.716 15 15s-6.717 15-15 15h-17.135v27.841h17.135c8.283 0 15 6.716 15 15s-6.717 15-15 15h-17.135v59.248 26.432h16.865.16l155.801-186.961c4.305 2.167 9.093 3.327 13.977 3.327 9.228 0 17.909-4.037 23.816-11.076l10.331-12.309c5.323-6.343 7.855-14.396 7.132-22.676-.522-5.951-2.687-11.49-6.215-16.115l15.656-18.787c5.305-6.366 4.446-15.825-1.919-21.129z"/>
                                    <path fill="#566574" fillRule="evenodd" d="m293.355 353.467h92.408l-17.884-89.427z"/>
                                    <path fill="#566574" fillRule="evenodd" d="m391.763 383.467-338.646-.001-18.968 94.839c-1.71 8.553.331 17.146 5.603 23.576 5.271 6.431 13.299 10.118 22.022 10.118h321.333c8.725 0 16.75-3.688 22.021-10.118 5.272-6.431 7.314-15.024 5.602-23.576z"/>
                                </g>
                            </svg>
                        </div>
                        {showMetronomeControls &&
                        <div className="metronome-controls" ref={metronomeControls}>
                            <div className="slider metronome-slider" data-direction="vertical" ref={sliders}>
                                <div className="progress" ref={metronomeProgress}>
                                    <div className="pin" id="metronome-pin" data-method="changeTempo" onClick={changeTempo}></div>
                                </div>
                            </div>
                            <div className={"metronome-mute-btn"}>
                                <Form.Check aria-label="option 1" defaultChecked={isMetronomeSoundOn} onChange={toggleMetronomeSound} />
                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">*/}
                                {/*    <path fill="#dcdcdc" fillRule="evenodd"*/}
                                {/*          d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z"*/}
                                {/*          id="speaker"/>*/}
                                {/*</svg>*/}
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

PlayControls.propTypes = {};
PlayControls.defaultProps = {};

export default PlayControls;
