import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {string_to_slug} from "../../../utils/helpers";
import {
    Link,
    useParams
} from "react-router-dom";

function EventHeader(props) {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [upper, setUpper] = useState(null);

    useEffect(() => {
        let startTime = new Date(props.event.start);
        let endTime = new Date(props.event.end);
        let now = new Date();
        //endTime.setHours(startTime.getHours()+1);
        setStartTime(startTime);
        setEndTime(endTime);
        if (startTime.getMonth() === now.getMonth()) {
            setUpper(startTime.toString().split(' ')[0]);
        } else {
            setUpper(startTime.toString().split(' ')[1]);
        }
    }, []);
    
    if (startTime==null) return <p>Loading...</p>;
    
    return (
        <Row className={"p-0"} noGutters={true} className={"eventHeaderNameCol"}>
            <Col xs={10} md={9}>
                <Row noGutters={true}>
                    <h1 style={{fontSize:"1.8rem"}}>{props.event.name}</h1>
                </Row>
                { (props.event.artist != null) &&
                <Row noGutters={true}>
                    <Link to={"/"+string_to_slug(props.event.artist.name)+"/artist/"+props.event.artist.id}>{props.event.artist.name}</Link>
                </Row>
                }
            </Col>
            <Col xs={2} sm={3} style={{textAlign:"center"}}>
                <div className={"calendarUpper"}>{upper}</div>
                <div className={"calendarLower"} style={{fontSize:"1.6rem",display:"block",lineHeight:"1"}}>{startTime.toString().split(' ')[2]}</div>
            </Col>
        </Row>
    );
}

EventHeader.propTypes = {};
EventHeader.defaultProps = {};

export default EventHeader;
