import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Unity, { UnityContent } from "react-unity-webgl";
import {PianoContext} from "../utils/PianoContext";


function PianoComp(props) {
    const [newUnityContent, setNewUnityContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [pianoSynth, setPianoSynth] = useState(null);
    const {pianos, setPianos} = useContext(PianoContext);
    
    //
    useEffect(() => {
        let unityContent = null;
        
        if (props.camera === 'wide-shot') {
            unityContent = new UnityContent(
                "/wide-shot-build/Wide Shot Build.json",
                "/wide-shot-build/UnityLoader.js"
            );
        } else {
            unityContent = new UnityContent(
                "/keys-build/WebGL Builds.json",
                "/keys-build/UnityLoader.js"
            );
        }
       

        props.setCallables({
            onEvent: (event) => {
                if (event.command === 144) {
                    //let noteGain = event.noteOn.velocity / 127;
                    unityContent.send(
                        "Piano",
                        "KeyDown",
                        event.data1
                    );
                    //console.log("noteOn: "+event.data1);
                } else if (event.command === 128) {
                    unityContent.send(
                        "Piano",
                        "KeyUp",
                        event.data1
                    );
                } else if (event.command === 176) {
                    unityContent.send(
                        "Piano",
                        "SustainPedalChange",
                        event.data2
                    );
                }
            },
            onResetPiano: () => {
                unityContent.send(
                    "Piano",
                    "ResetPiano"
                );
            }
        });
        setNewUnityContent(unityContent);
        
        setLoading(false);
    },[]);
    
    useEffect(() => {
        if (newUnityContent != null) {
            newUnityContent.on("PianoReady", () => {
                
            });
        }
    },[newUnityContent]);
    

    if (loading) return <p>Loading...</p>;
    
    if (props.camera === 'wide-shot') {
        return (
            <div className={'wideShotPianoContainer'}>
                <Unity unityContent={newUnityContent} />
            </div>
        );
    } else {
        return (
            <div className={'bottomPianoContainer'}>
                <Unity unityContent={newUnityContent} />
            </div>
        );
    }
}

PianoComp.propTypes = {};
PianoComp.defaultProps = {};

export default PianoComp;
