import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import ItemList from "./ItemList";
import {UserContext} from "../utils/UserContext";
import {useHistory} from "react-router-dom";

function Logout(props) {

    let history = useHistory();
    const {user, setUser} = useContext(UserContext);

    useEffect(() => {
        setUser(null);
        localStorage.setItem('user', null);
        history.push("/");
    },[]);
    
    return null;
}

Logout.propTypes = {};
Logout.defaultProps = {};

export default Logout;
