import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {UserContext} from "../utils/UserContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {postToDB} from "../utils/helpers";
import {ToastContext} from "../utils/ToastContext";
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import EventAdmin from "./Events/EventAdmin";

function Admin(props) {
    const {user, setUser} = useContext(UserContext);
    const {toasts, addToast} = useContext(ToastContext);
    
    const [ticketObject, setTicketObject] = useState({
        eventId: null,
        seats: "",
        userId: null
    });
    
    function handleInputChange(e) {
        const { name, value } = e.target;

        setTicketObject(prevTicketObject => {
            return {
                ...prevTicketObject,
                [name]:value
            }
        });
    }
    
    async function handleAddTickets(e) {
        e.preventDefault();
        
        if (ticketObject != null) {
            let ticketArray = ticketObject.seats.split(",");
            //console.log(ticketObject);
            ticketArray.forEach(seat => {
                let tempTicketObject = {
                    user: (ticketObject.userId === '' || ticketObject.userId == null) ? null : ticketObject.userId,
                    event: ticketObject.eventId,
                    seat: seat
                };
                //console.log(tempTicketObject);
                postToDB(user, "tickets", tempTicketObject);
            });
            addToast("Success!","Tickets were added successfully to the database.");
        }
    }
    
    useEffect(() => {
        console.log(user);
    },[user]);
    
    useEffect(() => {
        
    },[]);
    
    if (user==null)
        return (<p>You must be logged in to view this page.</p>);
    
    if (user.user.role.name!=="Admin")
        return (<p>You must have admin permissions to view this page.</p>);
    
    return (
        <Container>
            <h1>Admin</h1>
            <Row>
                <Col md={6}>
                    <h2>Create Tickets</h2>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formName">
                                <Form.Control
                                    type="number"
                                    name={"eventId"}
                                    placeholder="Event ID"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formSeats">
                                <Form.Control
                                    type="text"
                                    name={"seats"}
                                    placeholder="Seats"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="formUser">
                                <Form.Control
                                    type="number"
                                    name={"userId"}
                                    placeholder="UserID"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Button variant="primary" type="submit" onClick={handleAddTickets}>
                                    Create Tickets
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <EventAdmin timestamp={Date.now()} />
                </Col>
            </Row>
        </Container>
    );
}

Admin.propTypes = {};
Admin.defaultProps = {};

export default Admin;
