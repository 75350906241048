import React from 'react';
import PropTypes from 'prop-types';
import ItemList from "./ItemList";
import Footer from "./Footer";
import FeaturedEvent from "./FeaturedEvent";
import EventList from "./EventList";

function Dashboard(props) {
    return (
        <div>
            <FeaturedEvent/>
            <br />
            <EventList timestamp={Date.now()} />
            {/*<h2>Dashboard</h2>*/}
            {/*<h3>Latest Releases</h3>*/}
            {/*<ItemList type={"Performance"} order={"Created_at desc"} />*/}
            <Footer />
        </div>
    );
}

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default Dashboard;
