import React from 'react';
import PropTypes from 'prop-types';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function EventDetailRow(props) {
    
    return (
        <Row style={{marginBottom:"20px"}}>
            <Col xs={2} sm={2} style={{
                paddingTop: props.lowerTitle != null ? "6px" : "0px"
            }}>
                <img style={{width:"100%"}} src={props.imgSrc} />
            </Col>
            <Col>
                <Row>
                    {props.upperTitle}
                </Row>
                {
                    (props.lowerTitle != null) &&
                    <Row className={"detailsLower"}>
                        <small style={{display:"block", lineHeight:1.1}} className="text-muted">
                            {props.lowerTitle}
                        </small>
                    </Row>
                }
            </Col>
        </Row>
    );
}

EventDetailRow.propTypes = {};
EventDetailRow.defaultProps = {};

export default EventDetailRow;
