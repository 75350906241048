import React, {useEffect} from 'react';
import {Link, useParams} from "react-router-dom";
import PropTypes from 'prop-types';
import {API_URL} from "../utils/urls";
import useAxios from "axios-hooks";

function User(props) {
    let { id } = useParams();
    // let endpoint_url = API_URL+'/artists/'+id;
    // const [{ data, loading, error }, refetch] = useAxios(
    //     {
    //         url: endpoint_url,
    //         method: 'GET',
    //         //headers: {'Authorization': 'Bearer '+user.jwt}
    //     }
    // );

    // useEffect(() => {
    //     if (typeof data !== 'undefined') {
    //         //console.log(data);
    //         document.title = data.name+" | Artist on Grand Reality";
    //     } else {
    //         //console.log(data);
    //     }
    // }, [data]);

    // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error!</p>;

    return (
        <div>
            <p>User profiles coming soon!</p>
        </div>
    );
}

User.propTypes = {};
User.defaultProps = {};

export default User;
