import React, {useContext, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {parseArrayBuffer} from "midi-json-parser";
import axios from 'axios'
import {UserContext} from "../utils/UserContext";
import ImageCrop from "./ImageCrop";
import Alert from "react-bootstrap/Alert";
import {API_URL} from "../utils/urls";
import * as qs from "qs";
import Spinner from "react-bootstrap/Spinner";
import {getFromDB, postToDB} from "../utils/helpers";
import ButtonSpinner from "./reusable/ButtonSpinner";

function UploadMidi(props) {
    const [file, setFile] = useState(null);
    const [cover, setCover] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const fileInput = useRef(null);
    const composerNameInput = useRef('');
    const pieceNameInput = useRef('');
    const performerNameInput = useRef('');
    const performanceNameInput = useRef('');
    const isTempoMatchedInput = useRef('');
    const [isTempoMatched,setIsTempoMatched] = useState(false);
    const isPerformanceCoverInput = useRef('');
    const [isPerformanceCover,setIsPerformanceCover] = useState(true);
    const isComposerCoverInput = useRef('');
    const [isComposerCover,setIsComposerCover] = useState(false);
    const [fileName, setFileName] = useState('');
    const {user, setUser} = useContext(UserContext);
    
    function onMIDIBtnClick(event) {
        fileInput.current.click();
    }
    
    function onMIDIFileChanged(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
    }
    
    function validateInputs() {
        if (fileName == "") {
            setError("Please select a valid MIDI file.");
            return false;
        }
        if (performanceNameInput.current.value == "") {
            setError("Please enter a name for this performance. (e.g. 'Yamaha P45 Recording')");
            return false;
        }
        if (pieceNameInput.current.value == "" || pieceNameInput.current.value.length < 3) {
            setError("Please enter a name for this piece. (e.g. 'Concerto no. 5')");
            return false;
        }
        if (composerNameInput.current.value == "" || composerNameInput.current.value.length < 3) {
            setError("Please enter the composer's name. (e.g. 'Beethoven')");
            return false;
        }
        if (cover == null) {
            setError("Please select a cover image");
            return false;
        }
        
        return true;
    }

    async function uploadPiece(e){
        e.preventDefault();
        setIsLoading(true);
        
        if (!validateInputs()) {
            setIsLoading(false);
            return;
        }
        
        console.log(file);
        let reader = new FileReader();
        reader.onload = async function() {

            let arrayBuffer = this.result;
            parseArrayBuffer(arrayBuffer)
                .then(async (json) => {
                    // json is the JSON representation of the MIDI file.
                    console.log(json);
                    let performanceObject = {
                        midi: json,
                        hands: {}
                    };
                    let performanceJSONString = JSON.stringify(performanceObject);
                    let buf = Buffer.from(performanceJSONString, 'utf8');
                    var blob = new Blob([performanceJSONString], { type: 'application/json' });
                    var file = new File([blob], "performance.json", {type: "application/json"});
                    console.log(file);
                    let composer = await getFromDB(user, "composers?name="+composerNameInput.current.value);
                    if (composer[0] == null) {
                        composer[0] = await postToDB(user, "composers",{name:composerNameInput.current.value});
                    }
                    console.log(composer);
                    let pieceJson = {
                        name: pieceNameInput.current.value,
                        composer: composer[0].id
                    };
                    const query = qs.stringify({
                        _where: [pieceJson],
                    });
                    let piece = await getFromDB(user,"pieces?"+query);
                    if (piece.length == 0) {
                        piece = await postToDB(user,"pieces",pieceJson);
                    }
                    
                    let performerUserId = user.user.id;
                    let existingPerformer;
                    if (performerNameInput.current.value != "") {
                        existingPerformer = await getFromDB(user,"users?displayName="+encodeURI(performerNameInput.current.value));
                        if (existingPerformer[0] == null) {
                            const min = 1;
                            const max = 100;
                            const rand = Math.round(min + Math.random() * (max - min));
                            let username = encodeURI(performerNameInput.current.value.substring(0,5).replace(" ","")+rand);
                            existingPerformer[0] = await postToDB(user,"auth/local/register",{
                                username: username,
                                email: username+'@grandreality123.net',
                                password: 'dummy123',
                                displayName: performerNameInput.current.value
                            }, false);
                        }
                    } else {
                        existingPerformer = [];
                        existingPerformer[0] = {
                            id: performerUserId
                        };
                    }
                    
                    let performance = await postToDB(user,"performances",{
                        //PerformanceJson: file,
                        name: performanceNameInput.current.value,
                        piece: piece.id,
                        performerUser: existingPerformer[0].id,
                        isTempoMatched: isTempoMatched,
                        uploaderUser: user.user.id
                    });
                    //console.log(performance);
                    const jsonFormData = new FormData();
                    jsonFormData.append('files', file);
                    jsonFormData.append('refId', performance.id);
                    jsonFormData.append('ref', 'Performance');
                    jsonFormData.append('field', 'PerformanceJson');
                    let upload = await postToDB(user,"upload",jsonFormData);
                    //console.log(upload);
                    
                    if (isPerformanceCover) {
                        const coverData = new FormData();
                        let coverArtFile = new File([cover], "cover.jpeg", {type: "image/jpeg"});
                        coverData.append('files', coverArtFile);
                        coverData.append('refId', performance.id);
                        coverData.append('ref', 'Performance');
                        coverData.append('field', 'coverArt');
                        let upload2 = await postToDB(user,"upload",coverData);
                        //console.log(upload2);
                    }
                    
                    if (isComposerCover) {
                        const coverData = new FormData();
                        let coverArtFile = new File([cover], "cover.jpeg", {type: "image/jpeg"});
                        coverData.append('files', coverArtFile);
                        coverData.append('refId', composer[0].id);
                        coverData.append('ref', 'Composer');
                        coverData.append('field', 'picture');
                        let upload2 = await postToDB(user,"upload",coverData);
                        //console.log(upload2);
                    }
                    
                    setSuccess(true);
                    setIsLoading(false);
                });

        };
        reader.readAsArrayBuffer(file);
    }
    
    function handleTempoMatchedCheckboxChange(event) {
        setIsTempoMatched(event.target.checked);
    }

    function handlePerformanceCoverCheckboxChange(event) {
        setIsPerformanceCover(event.target.checked);
    }

    function handleComposerCoverCheckboxChange(event) {
        setIsComposerCover(event.target.checked);
    }
    
    if (success) {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="6">
                        <Alert variant={"success"}>
                            Successfully Uploaded!
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    }
    
    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="6">
                    {isLoading &&
                    <div>
                        <br />
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <p>Loading. Please wait...</p>
                    </div>
                    }
                    <div style={{display: isLoading ? 'none' : 'block' }}>
                        <h1>Choose a MIDI file</h1>
                        {error != null &&
                        <Alert variant={'danger'}>
                            {error}
                        </Alert>
                        }
                        <hr />
                        <Button variant="primary" size="lg" block onClick={onMIDIBtnClick}>
                            Select MIDI file
                        </Button>
                        <Form.Text className="text-muted">{fileName}</Form.Text>
                        <input ref={fileInput} type="file" name="file" onChange={onMIDIFileChanged} style={{'display':'none'}} accept=".midi,.mid" />
                        <hr />
                        <Form>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Performer Name</Form.Label>
                                <Form.Control ref={performerNameInput} type="text" placeholder="Enter performer's name (leave blank if it's your own performance)" />
                                {/*<Form.Text className="text-muted">*/}
                                {/*    We'll never share your email with anyone else.*/}
                                {/*</Form.Text>*/}
                            </Form.Group>
                            
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Performance Name</Form.Label>
                                <Form.Control ref={performanceNameInput} type="text" placeholder="Enter performance name" />
                                {/*<Form.Text className="text-muted">*/}
                                {/*    We'll never share your email with anyone else.*/}
                                {/*</Form.Text>*/}
                            </Form.Group>

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Piece Name</Form.Label>
                                <Form.Control ref={pieceNameInput} type="text" placeholder="Enter piece name" />
                                {/*<Form.Text className="text-muted">*/}
                                {/*    We'll never share your email with anyone else.*/}
                                {/*</Form.Text>*/}
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Piece Composer</Form.Label>
                                <Form.Control ref={composerNameInput} type="text" placeholder="Composer" />
                            </Form.Group>
                            <Form.Check
                                checked={isTempoMatched}
                                type={'checkbox'}
                                id={'tempoMatched'}
                                label={"Was this performance recorded to a click track?"}
                                ref={isTempoMatchedInput}
                                onChange={handleTempoMatchedCheckboxChange}
                            />
                            {/*<Form.Group controlId="formBasicCheckbox">*/}
                            {/*    <Form.Check type="checkbox" label="Check me out" />*/}
                            {/*</Form.Group>*/}
                            <hr />
                            <Form.Check
                                checked={isPerformanceCover}
                                type={'checkbox'}
                                id={'performanceCover'}
                                label={"Add as performance cover image"}
                                ref={isPerformanceCoverInput}
                                onChange={handlePerformanceCoverCheckboxChange}
                            />
                            <Form.Check
                                checked={isComposerCover}
                                type={'checkbox'}
                                id={'composerCover'}
                                label={"Add as composer cover image"}
                                ref={isComposerCoverInput}
                                onChange={handleComposerCoverCheckboxChange}
                            />
                            <ImageCrop callback={setCover} />
                            <hr />
                            <Button variant="primary" type="submit" onClick={uploadPiece}>
                                {isLoading === true &&
                                    <ButtonSpinner />
                                }
                                Upload
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

UploadMidi.propTypes = {};
UploadMidi.defaultProps = {};

export default UploadMidi;
