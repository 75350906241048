import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Soundfont from 'soundfont-player';
import Axios from "axios";
import { create } from 'midi-player';
import PianoComp from "./Piano";
import PlayControls from "./PlayControls";
import sequencer from 'heartbeat-sequencer';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ItemList from "./ItemList";
import {Piano} from "@tonejs/piano";

function Player(props) {
    let ac = new AudioContext();
    const [bpm, setBpm] = useState(120);
    const [performance, setPerformance] = useState(null);
    const [midiAccess, setMidiAccess] = useState(null);
    const [piano, setPiano] = useState(null);
    const [eventHandler, setEventHandler] = useState([]);
    const [midiTrack,setMidiTrack] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [pianoSynth, setPianoSynth] = useState(null);

    function setChildCallables(callables) {
        setEventHandler(oldArray => [...oldArray, callables]);
    };
    
    useEffect(() => {
        initSequencer();
        // Soundfont.instrument(ac, 'acoustic_grand_piano', { soundfont: 'MusyngKite' }).then(function(pianoInstrument) {
        //     setPiano(pianoInstrument);
        // });
        fetch(props.performance.PerformanceJson.url)
            .then((response) => response.json())
            .then((responseJson) => {
                //console.log(responseJson);
                setPerformance(responseJson);
                let midifile = {};
                let tempBPM;
                let timeEvents = [];
                midifile.base64 = '';
                midifile.numTracks = 0;
                let ppq = responseJson.midi.division;
                midifile.tracks = [];
                responseJson.midi.tracks.forEach(function(jsonTrack) {
                    //console.log(jsonTrack);
                    let part = sequencer.createPart();
                    let track = sequencer.createTrack();
                    const events = [];
                    let tmpTicks = 0;
                    let totalEventCount = 0;
                    jsonTrack.forEach(function(jsonEvent) {
                        let eventType = 0;
                        let d1 = 0;
                        let d2 = 0;
                        tmpTicks += jsonEvent.delta;
                        if (jsonEvent.noteOn) {
                            eventType = 144;
                            d1 = jsonEvent.noteOn.noteNumber;
                            d2 = jsonEvent.noteOn.velocity;
                        } else if (jsonEvent.noteOff) {
                            eventType = 128;
                            d1 = jsonEvent.noteOff.noteNumber;
                            d2 = jsonEvent.noteOff.velocity;
                        } else if (jsonEvent.setTempo) {
                            tempBPM = (60000000/jsonEvent.setTempo.microsecondsPerQuarter);
                            setBpm(tempBPM);
                            //eventType = 81;
                            //d1 = tempBPM;
                            //timeEvents.push(sequencer.createMidiEvent(tmpTicks, sequencer.TEMPO, tempBPM));
                        } else if (jsonEvent.controlChange) {
                            eventType = 176;
                            d1 = jsonEvent.controlChange.type;
                            d2 = jsonEvent.controlChange.value;
                        }
                        if (eventType > 0) {
                            const midiEvent = sequencer.createMidiEvent([
                                tmpTicks,
                                eventType,
                                d1,
                                d2
                            ]);
                            events.push(midiEvent);
                        }
                        totalEventCount++;
                    });
                    //console.log("total event count = "+totalEventCount);
                    if (events.length > 0) {
                        part.addEvents(events);
                        track.addPart(part);
                        midifile.tracks.push(track);
                        midifile.numTracks++;
                    }
                });
                //midifile.autoSize = true;
                midifile.loaded = true;
                midifile.useMetronome = true;
                midifile.ppq = ppq;
                midifile.bpm = tempBPM;
                let midiTrack = sequencer.createSong(midifile);
                setMidiTrack(midiTrack);
                sequencer.addAssetPack({url: '/assets/heartbeat_asset_pack.json'}, () => {
                    // set all tracks of the song to use 'piano'
                    midiTrack.tracks.forEach(function(track){
                        //console.log(track);
                        //track.setInstrument('piano');
                        track.setVolume(0);
                    });
                },(midiTrack));
                // create the piano and load 5 velocity steps
                const pianoTemp = new Piano({
                    velocities: 5
                });
                //connect it to the speaker output
                pianoTemp.toDestination();
                pianoTemp.load().then(() => {
                    //console.log('loaded piano');
                    setPianoSynth(pianoTemp);
                });
            })
            .catch((error) => {
                console.error(error);
            });
        if (navigator.requestMIDIAccess) {
            //console.log('This browser supports WebMIDI!');
            getMidiAccess();
        } else {
            console.log('WebMIDI is not supported in this browser.');
        }
    },[]);
    
    useEffect(() => {
        if (midiTrack != null) {
            //attachEventHandlers();
        }
    }, [midiTrack]);

    async function getMidiAccess() {
        setMidiAccess(await navigator.requestMIDIAccess());
        //console.log(midiAccess);
    }

    const initSequencer = async () => {
        await sequencer.ready(function init() {
            // let timeEvents = [];
            // timeEvents.push(sequencer.createMidiEvent(ticks, sequencer.TEMPO, bpm));
        });
    };

    // async function loadAndPlayMidi(json) {
    //     //console.log(midiAccess);
    //     const midiOutput = Array.from(midiAccess.outputs.values())[0];
    //     //console.log(midiOutput);
    //     const midiPlayer = create({ json, midiOutput, onEvent });
    //     //console.log(midiPlayer);
    //     //await midiPlayer.play();
    // }

    let noteOnOffHandler = function() {};
    
    useEffect(() => {
        if (pianoSynth!=null) {
            noteOnOffHandler = function(event) {
                if (event.command == 144) {
                    let noteGain = event.data2 / 127;
                    pianoSynth.keyDown({
                        midi: event.data1,
                        velocity: noteGain
                    });
                } else if (event.command == 128) {
                    pianoSynth.keyUp({
                        midi: event.data1
                    });
                } else if (event.command == 176 && event.data1 == 64) {
                    if (event.data2 < 64) {
                        pianoSynth.pedalUp();
                    } else {
                        pianoSynth.pedalDown();
                    }
                }
                if (eventHandler.length > 0) {
                    eventHandler.forEach(handler => {
                        handler.onEvent(event);
                    });
                }
            };
            attachEventHandlers();
        }
    },[pianoSynth]);
    
    function attachEventHandlers() {
        //loadAndPlayMidi(performance.midi);
        //console.log(midiTrack);
                
        
        midiTrack.addEventListener('event', 'type = NOTE_ON OR type = NOTE_OFF OR type = CONTROL_CHANGE', noteOnOffHandler);
        midiTrack.addEventListener('play', function(){
            midiTrack.bpm = bpm;
            //console.log("--- started playback ---");
            //console.log(Object.values(midiTrack.followEvent.allListenersById).length);
            setIsPlaying(true);
        });
        midiTrack.addEventListener('pause', function() {
            //console.log("--- paused playback ---");
            //console.log(Object.values(midiTrack.followEvent.allListenersById).length);
            midiTrack.removeEventListener('event','type = NOTE_ON OR type = NOTE_OFF OR type = CONTROL_CHANGE');
            midiTrack.addEventListener('event', 'type = NOTE_ON OR type = NOTE_OFF OR type = CONTROL_CHANGE', noteOnOffHandler);
            //console.log(Object.values(midiTrack.followEvent.allListenersById).length);
            setIsPlaying(false);
        });
        midiTrack.addEventListener('position', 'beat', function(event) {
            // if (eventHandler != null) {
            //     eventHandler.onProgress();
            // }
            if (eventHandler.length > 0) {
                eventHandler.forEach(handler => {
                    if (handler.onProgress != null) {
                        handler.onProgress(midiTrack);
                    }
                });
            }
        });
    }

    // function onEvent(event, time) {
    //     //console.log(event);
    //     if (event.noteOn) {
    //         // let noteGain = event.noteOn.velocity / 127;
    //         // piano.schedule(ac.currentTime, [
    //         //     { note: event.noteOn.noteNumber, time: time/1000, gain: noteGain, duration: 1 }
    //         // ]);
    //     }
    //     if (eventHandler.length > 0) {
    //         eventHandler.forEach(handler => {
    //             handler.onEvent(event, time);
    //         });
    //     }
    // }
    
    return (
        <div>
            <div className={'topHalfContainer'}>
                <div className={'topPianoContainer'}>
                    <PianoComp camera={'wide-shot'} setCallables={setChildCallables} />
                </div>
                <div className={'recommendations'}>
                    <div className={'recommsHeader'}>
                        Recommended
                    </div>
                    <div className={'recommsContainer'}>
                        <ItemList type={"Performance"} order={"Created_at desc"} size={"small"} />
                    </div>
                </div>
            </div>
            <div className={'bottomPianoSection'}>
                <PlayControls midiTrack={midiTrack} piano={piano} eventHandler={eventHandler} setCallables={setChildCallables} isPlaying={isPlaying} isTempoMatched={props.performance.isTempoMatched} />
                <PianoComp camera={'keys'} setCallables={setChildCallables} />
            </div>
        </div>
    );
}

Player.propTypes = {};
Player.defaultProps = {};

export default Player;
