import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../utils/UserContext";
import {API_URL} from "../utils/urls";
import {useHistory, useLocation} from "react-router-dom";
import Axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import TextInput from "./formInputs/TextInput";
import Spinner from "react-bootstrap/Spinner";

function RegisterOrLogin(props) {
    let history = useHistory();
    const [username,setUsername] = useState('');
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [vrCode,setVrCode] = useState('');
    const [submitButtonDisabled,setSubmitButtonDisabled] = useState(true);
    const [mode,setMode]= useState("Login");
    const {user, setUser} = useContext(UserContext);
    const [error, setError] = useState(null);
    const [formIsLoading, setFormIsLoading] = useState(false);
    const [failedAttempts, setFailedAttempts] = useState(0);
    

    const handleSubmit = async (event) => {
        setFormIsLoading(true);
        setSubmitButtonDisabled(true);
        event.preventDefault();
        const data = {
            identifier: email,
            email,
            password,
            username: username
        };
        let url = '';
        if (mode === 'Login') {
            url = `${API_URL}/auth/local`;
        } else {
            url = `${API_URL}/auth/local/register`;
        }
        
        try {
            const userCreationResult = await Axios({
                method: 'POST',
                url,
                data
            });
            //console.log("RegisterOrLogin.handleSubmit userCreationResult",userCreationResult);
            setUser(userCreationResult.data);
            localStorage.setItem('user', JSON.stringify(userCreationResult.data));
            if (vrCode !== '') {
                await pushVRCode(vrCode, userCreationResult.data.jwt);
            } else {
                history.push("/");
            }
        } catch (e) {
            //console.log(e);
            setError("Couldn't "+mode+". Please check your email address and password.");
            setFailedAttempts(failedAttempts+1);
        }
        setFormIsLoading(false);
    };
    
    async function pushVRCode(code, jwt) {
        let data = {
            code: code,
            JWT: jwt
        };
        let url = `${API_URL}/vr-link-codes`;
        const codeCreationResult = await Axios({
            method: 'POST',
            url,
            data
        });
        history.push("/vr?success=true");
    }
    
    function handleInputFieldUpdate(updateFunction, value) {
        updateFunction(value);
        setSubmitButtonDisabled(!isValidInput());
    }
    
    function isValidInput() {
        if (username==='' && mode==='Register')
            return false;
        if (password==='')
            return false;
        if (email==='')
            return false;
        
        return true;
    }

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();

    useEffect(() => {
        if (query.get("default")) {
            setMode(query.get("default"));
        }
        if (query.get("vrcode")) {
            setVrCode(query.get("vrcode"));
            if (user) {
                pushVRCode(query.get("vrcode"), user.jwt);
            }
        }
    },[]);
    
    useEffect(() => {
        setError(null);
    },[mode]);
    
    if (user) {
        return (
            <Container>
                <Row className="justify-content-md-center">
                    <Col xs lg="6">
                        <p className="text-muted">Nice try. You are already registered!</p>
                    </Col>
                </Row>
            </Container>
        );
    }
    

    return (
        <Container>
            <Row className="justify-content-md-center">
                <Col xs lg="6">
                    <h1>{mode}</h1>
                    <hr />
                    {error != null &&
                    <Alert variant={'danger'}>
                        {error}
                    </Alert>
                    }
                    {failedAttempts > 1 &&
                    <p>Having problems? Please let me know on <a href={'https://discord.gg/H5YAGaWW33'}>Discord</a> so that I can try to help.</p>
                    }
                    <Form onSubmit={handleSubmit}>
                        {mode === 'Register' &&
                            <div>
                                <TextInput placeholder={"Username"} type={"text"} onChange={(event) => handleInputFieldUpdate(setUsername, event.target.value)} text={"Please enter a username without spaces"} />
                                <TextInput placeholder={"Password"} type={"password"} onChange={(event) => handleInputFieldUpdate(setPassword, event.target.value)} text={"Please choose a password with at least 8 characters"} />
                                <TextInput placeholder={"Email"} type={"email"} onChange={(event) => handleInputFieldUpdate(setEmail, event.target.value)} text={"We'll never share your email with anyone else"} />
                            </div>
                        }
                        {mode === 'Login' &&
                            <div>
                                <TextInput placeholder={"Email"} type={"email"} onChange={(event) => handleInputFieldUpdate(setEmail, event.target.value)} />
                                <TextInput placeholder={"Password"} type={"password"} onChange={(event) => handleInputFieldUpdate(setPassword, event.target.value)} />
                            </div>
                        }
                        <Button variant="primary" type="submit" disabled={submitButtonDisabled}>
                            {formIsLoading === true &&
                            <div>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                /> <br />
                            </div>
                            }
                            {mode}
                        </Button>
                        <hr />
                        {mode === 'Login' &&
                        <Button variant={'link'} onClick={() => setMode('Register')}>Register</Button>
                        }
                        {mode === 'Register' &&
                        <Button variant={'link'} onClick={() => setMode('Login')}>Login</Button>
                        }
                        <Button variant={'link'}>Reset Password</Button>
                        {mode === 'Register' &&
                            <div>
                                <hr/>
                                <p className="text-muted">By clicking ‘Register’ or signing up, you agree to the <a target={"_blank"} href={"/PrivacyPolicy"}>Privacy Policy</a>. You also agree to receive information and offers relevant to our services via email. You can unsubscribe from these emails at any time.</p>
                            </div>
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

RegisterOrLogin.propTypes = {};
RegisterOrLogin.defaultProps = {};

export default RegisterOrLogin;
