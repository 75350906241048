import React from 'react';
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Socials from "./Socials";

function Footer(props) {
    return (
        <footer className={"page-footer font-small stylish-color-dark pt-4"}>
            <Container className={"text-center text-md-left"}>
                <Row>
                    <Col className={"md-4 mx-auto"}>
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Hi. I'm new here</h5>
                        <p>Do you find this web app helpful? Do you have ideas or feedback? I'm keen to talk to you over on the <a href={"https://discord.com/invite/r7kRJfu"} target={"_blank"}>Discord server.</a></p>
                        <p>Thanks, Robin</p>
                    </Col>
                    <hr className="clearfix w-100 d-md-none" />
                    <Col className={"col-md-2 mx-auto"}>
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Apps</h5>

                        <ul className="list-unstyled">
                            <li>
                                <a target={"_blank"} href="https://grandreality.app">Oculus Quest</a>
                            </li>
                            <li>
                                <a href="#!">Windows (coming soon)</a>
                            </li>
                        </ul>
                    </Col>
                    <hr className="clearfix w-100 d-md-none" />
                    <Col className={"col-md-2 mx-auto"}>
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">About</h5>

                        <ul className="list-unstyled">
                            <li>
                                <a href="/about-us">About Us</a>
                            </li>
                            <li>
                                <a href="/contact-us">Contact Us</a>
                            </li>
                        </ul>
                    </Col>
                    <hr className="clearfix w-100 d-md-none" />
                    <Col className={"col-md-2 mx-auto"}>
                        <h5 className="font-weight-bold text-uppercase mt-3 mb-4">Community</h5>

                        <ul className="list-unstyled">
                            <li>
                                <a href="/privacy-policy">Privacy Policy</a>
                            </li>
                            <li>
                                <a href="/feedback">Feedback</a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <hr />
            <ul className="list-unstyled list-inline text-center py-2">
                <li className="list-inline-item">
                    <h5 className="mb-1">Register for free</h5>
                </li>
                <li className="list-inline-item">
                    <a href="/RegisterOrLogin?default=Register" className="btn btn-danger btn-rounded">Sign up!</a>
                </li>
            </ul>

            <hr />

                <Socials />

                <div className="footer-copyright text-center py-3">© {new Date().getFullYear()} Copyright:
                    <a href="https://grandreality.net"> GrandReality.net</a>
                </div>
        </footer>
    );
}

Footer.propTypes = {};
Footer.defaultProps = {};

export default Footer;
