import ReactDOM from 'react-dom';
import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Button from "react-bootstrap/Button";

class ImageCrop extends PureComponent {
    state = {
        src: null,
        crop: {
            unit: '%',
            width: 100,
            aspect: 1 / 1,
        },
    };
    
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    
    onButtonClick = e => {
        this.inputRef.current.click();
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
            console.log(croppedImageUrl);
            console.log(this.imageRef);

            let blob = await fetch(croppedImageUrl).then(r => r.blob());
            
            this.props.callback(blob);

            // upload to Firebase
            //setImageAsFile(imageFile => (file));
            // console.log(imageAsFile);
            //const uploadTask = storage.ref(`/images/${this.imageRef.name}`).put(this.imageRef);
            // initiates the firebase side uploading 
            // uploadTask.on('state_changed',
            //     (snapShot) => {
            //         //takes a snap shot of the process as it is happening
            //         console.log(snapShot)
            //     }, (err) => {
            //         //catches the errors
            //         console.log(err)
            //     }, () => {
            //         // gets the functions from storage refences the image storage in firebase by the children
            //         // gets the download url then sets the image from firebase as the value for the imgUrl key:
            //         storage.ref('images').child(this.imageRef.name).getDownloadURL()
            //             .then(fireBaseUrl => {
            //                 console.log(fireBaseUrl);
            //                 //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
            //             })
            //     }
            // );
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div>
                <Button variant="primary" size="lg" block onClick={this.onButtonClick}>
                    Select Cover Art
                </Button>
                <input ref={this.inputRef} style={{display:"none"}} type="file" accept="image/*" onChange={this.onSelectFile} />
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                )}
                {croppedImageUrl && (
                    <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImageUrl} />
                )}
            </div>
        );
    }
}

export default ImageCrop;