import React, {useEffect, useState} from 'react';
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import Image from "react-bootstrap/Image";
import {string_to_slug} from "../utils/helpers";
import Carousel from "react-bootstrap/Carousel";

function FeaturedEvent(props) {
    
    let endpoint_url = API_URL+'/events?isFrontPageFeatured=true';
    const [index, setIndex] = useState(0);
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: endpoint_url,
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        if (typeof data !== 'undefined') {
            //console.log(data);
        } else {
            //console.log(data2);
        }
    }, [data]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    if (data.length === 0) return null;
    
    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            { data.map((event) => (
                <Carousel.Item key={event.id}>
                    <a href={string_to_slug(data[0].name)+"/event/"+data[0].id}>
                        <img
                            className="d-block w-100"
                            src={event.banner.url}
                            alt="First slide"
                        />
                        <Carousel.Caption>
                            {/*<h3>{event.name}</h3>*/}
                            {/*<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                        </Carousel.Caption>
                    </a>
                </Carousel.Item>
            )) }
        </Carousel>
        // <div>
        //     <a href={string_to_slug(data[0].name)+"/event/"+data[0].id}>
        //         <Image src={data[0].banner.url} fluid />
        //     </a>
        // </div>
    );
}

FeaturedEvent.propTypes = {};
FeaturedEvent.defaultProps = {};

export default FeaturedEvent;
