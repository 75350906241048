import React, {useEffect} from 'react';
import {
    useParams
} from "react-router-dom";
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import Player from "./Player";

function Performance() {
    let { id } = useParams();
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: API_URL+'/performances/'+id,
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    useEffect(() => {
        if (typeof data !== 'undefined') {
            //console.log(data);
            document.title = data.piece.name+" | Learn to Play on Piano";
        } else {
            //console.log(data);
        }
    }, [data]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    
    return (
        <div>
            {/*<input type={"text"} value={data.PerformanceJson.url} />*/}
            {/*{JSON.stringify(data, null, 2)}*/}
            <Player performance={data} />
        </div>
    );
}

export default Performance;
