import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Toast from "react-bootstrap/Toast";

function ToastElement(props) {
    const [showToast, setShowToast] = useState(true);

    const toggleShowToast = () => setShowToast(!showToast);
    
    return (
        <Toast  show={showToast} onClose={toggleShowToast}>
            <Toast.Header>
                <img src="holder.js/20x20?text=%20" className="rounded mr-2" alt="" />
                <strong className="mr-auto">{props.title}</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>{props.content}</Toast.Body>
        </Toast>
    );
}

ToastElement.propTypes = {};
ToastElement.defaultProps = {};

export default ToastElement;
