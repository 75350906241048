import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {API_URL} from "../utils/urls";
import useAxios from 'axios-hooks'
import Item from "./Item";

function SearchInput(props) {
    const [showResults, _setShowResults] = React.useState(false);
    const showResultsRef = React.useRef(showResults);
    const setShowResults = data => {
        showResultsRef.current = data;
        _setShowResults(data);
    };
    const [searchTerm, setSearchTerm] = React.useState("");
    const [searchResults, setSearchResults] = React.useState([]);
    const searchInput = useRef();
    const searchResultsContainer = useRef();
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };
    const handleClick = event => {
        if (searchInput.current.contains(event.target) || (showResultsRef.current && searchResultsContainer.current.contains(event.target))) {
            // inside click
            setShowResults(true);
        } else {
            // outside click
            setShowResults(false);
        }
    };
    const [{ data, loading, error }, refetch] = useAxios(
        {
            //url: API_URL+'/performances?_where[piece.name_contains]='+searchTerm+'&_limit=10',
            url: API_URL+'/pieces?name_contains='+searchTerm+'&_limit=10',
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    React.useEffect(() => {
        if (data != undefined) {
            let performances = [];
            data.forEach(item => {
                if (item.performances[0] != undefined) {
                    item.performances[0].piece = {
                        composer: item.composer,
                        name: item.name,
                    };
                    performances.push(item.performances[0]);
                }
            });
            setSearchResults(performances);
        }
    }, [searchTerm,data]);
    
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClick);

        return function cleanup() {
            document.removeEventListener("mousedown", handleClick);
        };
    },[]);
    
    return (
        <div className={"searchInputContainer"}>
            <input value={searchTerm} onChange={handleChange} bg={'dark'} type="text" placeholder="Search" className={'navSearch'} ref={searchInput} />
            {(showResults===true && !loading && (searchResults != null)) &&
            <ul className={"searchResultsContainer"} ref={searchResultsContainer}>
                {searchResults.map(item => (
                    <Item key={item.id} item={item} size={"small"} />
                ))}
            </ul>
            }
        </div>
    );
}

SearchInput.propTypes = {};
SearchInput.defaultProps = {};

export default SearchInput;
