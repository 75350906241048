import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {UserContext} from "./utils/UserContext";
import {ToastContext} from "./utils/ToastContext";
import GRNavBar from "./components/GRNavBar";
import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import RegisterOrLogin from "./components/RegisterOrLogin";
import ItemList from "./components/ItemList";
import Performance from "./components/Performance";
import Midi2Json from "./components/Midi2Json";

import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from "./components/NotFound";
import Logout from "./components/Logout";
import UploadMidi from "./components/UploadMidi";
import MyUploads from "./components/MyUploads";
import PrivacyPolicy from "./components/PrivacyPolicy";
import VRCode from "./components/VRCode";
import Event from "./components/Event";
import jwt_decode from "jwt-decode";
import Artist from "./components/Artist";
import User from "./components/User";
import BookingForm from "./components/BookingForm";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import Admin from "./components/Admin";
import ToastDisplay from "./components/ToastDisplay";
import ToastElement from "./components/reusable/ToastElement";
import CheckoutReturn from "./components/CheckoutReturn";

function App() {
    const stripePromise = loadStripe('pk_test_ZvbKpDonMLEVSth6KAsn1AE600LPXQxmhO');
    
    const [user,setUser] = useState(null);
    const [toasts, setToasts] = useState([]);
    const [appElementStyle, setAppElementStyle] = useState({});

    const logout = () => {
        localStorage.removeItem('user');
        setUser(null);
    };
    
    const addToast = (title, content) => {
        setToasts(prevToastElements => {
            return [
                ...prevToastElements,
                <ToastElement title={title} content={content} />
            ]
        });
    };

    useEffect(() => {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user'));
            if (user == null)
                return;
            if (checkJWTisValid(user)) {
                setUser(user);
            } else {
                logout();
            }
        }
    },[]);
    
    function checkJWTisValid(user) {
        //console.log(user);
        const { exp } = jwt_decode(user.jwt);
        //console.log(exp);
        const current_time = new Date().getTime() / 1000;
        //console.log(current_time);
        return current_time <= exp;
    }
    
    function setBackgroundImage(url) {
        let backgroudStyle = {
            backgroundImage: "url(\""+url+"\")"
        };
        //console.log(backgroudStyle);
        setAppElementStyle(backgroudStyle);
    }
    
    return (
        <Router>
            <div className={"backgroundImage"} style={appElementStyle}></div>
            <div className={"App"}>
                <UserContext.Provider value={{user,setUser}}>
                    <ToastContext.Provider value={{toasts, addToast}}>
                        <GRNavBar user={user} />
                        <ToastDisplay />
                        <Switch>
                            {!user &&
                            <Route path={"/"} exact component={Home} />
                            }
                            {user &&
                            <Route path={"/"} exact component={Dashboard} />
                            }
                            <Route path={"/Admin"} exact component={Admin} />
                            <Route path={"/RegisterOrLogin"} exact component={RegisterOrLogin} />
                            <Route path={"/PrivacyPolicy"} exact component={PrivacyPolicy} />
                            <Route path={"/privacy-policy"} exact component={PrivacyPolicy} />
                            <Route path={"/vr"} exact component={VRCode} />
                            <Route path={"/:composer/:piece/pe/:id"}>
                                <Performance/>
                            </Route>
                            <Route path={"/:piece/pe/:id"}>
                                <Performance/>
                            </Route>
                            <Route path={"/:event/event/:id"}>
                                <Event setBackgroundStyle={setBackgroundImage} />
                            </Route>
                            {/*<Route path={"/booking"} exact component={BookingForm}>*/}
                            {/*    <Elements stripe={stripePromise}>*/}
                            {/*        <BookingForm />*/}
                            {/*    </Elements>*/}
                            {/*</Route>*/}
                            <Route path={"/:name/artist/:id"}>
                                <Artist />
                            </Route>
                            <Route path={"/:name/user/:id"}>
                                <User />
                            </Route>
                            <Route path={"/upload-midi"} exact component={UploadMidi} />
                            <Route path={"/my-uploads"} exact component={MyUploads} />
                            <Route path={"/midi2json"} exact component={Midi2Json} />
                            <Route path={"/logout"} exact component={Logout} />
                            <Route path={"/CheckoutReturn"} exact component={CheckoutReturn} />
                            <Route component={NotFound} />
                        </Switch>
                    </ToastContext.Provider>
                </UserContext.Provider>
            </div>
        </Router>
    );
}

export default App;
