import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import useAxios from "axios-hooks";
import {API_URL} from "../../utils/urls";
import {UserContext} from "../../utils/UserContext";

function SeatMap(props) {
    const {user, setUser} = useContext(UserContext);
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: API_URL+'/tickets?event_eq='+props.event.id+'&_sort=seat:ASC',
            method: 'GET',
            headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    
    return (
        <div>
            { data.map(ticket => (
                <div>
                    {ticket.id} - {ticket.seat} - {ticket.user != null ? ticket.user.username : "empty"}
                </div>                
            )) }
        </div>
    );
}

SeatMap.propTypes = {};
SeatMap.defaultProps = {};

export default SeatMap;
