import React from 'react';
import { SiTwitter,SiFacebook,SiMedium,SiInstagram,SiDiscord } from "react-icons/si";

function Socials(props) {
    return (
        <ul className="list-unstyled list-inline text-center">
            <li className="list-inline-item">
                <a href={"https://twitter.com/GrandRealityApp"} className="btn-floating btn-li">
                    <SiTwitter />
                </a>
            </li>
            <li className="list-inline-item">
                <a href="https://www.facebook.com/GrandRealityPiano" className="btn-floating btn-li">
                    <SiFacebook />
                </a>
            </li>
            <li className="list-inline-item">
                <a href={"https://medium.com/@grand_reality"} className="btn-floating btn-li">
                    <SiMedium />
                </a>
            </li>
            <li className="list-inline-item">
                <a href={"https://www.instagram.com/grand_reality/"} className="btn-floating btn-li">
                    <SiInstagram />
                </a>
            </li>
            <li className="list-inline-item">
                <a href={"https://discord.com/invite/r7kRJfu"} className="btn-floating btn-li">
                    <SiDiscord />
                </a>
            </li>
        </ul>
    );
}

Socials.propTypes = {};
Socials.defaultProps = {};

export default Socials;
