import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
//import '../style/stripe.css';
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function BookingForm(props) {
    const stripePromise = loadStripe('pk_test_ZvbKpDonMLEVSth6KAsn1AE600LPXQxmhO');
    
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [billingDetails, setBillingDetails] = useState({
        email: '',
        phone: '',
        name: '',
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
        });
        props.setPaymentMethod(paymentMethod);
    };

    // const handleSubmit = async (event) => {
    //     event.preventDefault();
    //
    //     if (!stripe || !elements) {
    //         // Stripe.js has not loaded yet. Make sure to disable
    //         // form submission until Stripe.js has loaded.
    //         return;
    //     }
    //
    //     if (error) {
    //         //elements.getElement('card').focus();
    //         console.log(error);
    //         return;
    //     }
    //
    //     if (cardComplete) {
    //         setProcessing(true);
    //     }
    //    
    //     let processedDetails = {
    //         cvc: cardDetails.cvc,
    //         //name: cardDetails.name,
    //         number: cardDetails.number
    //     };
    //     let processedBillingDetails = {
    //         name: cardDetails.name
    //     };
    //     if (cardDetails.expiry.indexOf("/") > -1) {
    //         processedDetails.exp_month = cardDetails.expiry.split('/')[0];
    //         processedDetails.exp_year = cardDetails.expiry.split('/')[1];
    //     } else {
    //         processedDetails.exp_month = cardDetails.expiry.substr(0,2);
    //         processedDetails.exp_year = cardDetails.expiry.substr(2,2);
    //     }
    //
    //     const payload = await stripe.createPaymentMethod({
    //         type: 'card',
    //         card: processedDetails,
    //         billing_details: processedBillingDetails,
    //     });
    //
    //     setProcessing(false);
    //
    //     if (payload.error) {
    //         setError(payload.error);
    //     } else {
    //         props.setPaymentMethod(payload.paymentMethod);
    //         setPaymentLoading(false);
    //     }
    // };

    const reset = () => {
        setError(null);
        setProcessing(false);
        props.setPaymentMethod(null);
        setBillingDetails({
            email: '',
            phone: '',
            name: '',
        });
    };

    // function handleInputFocus(e) {
    //     e.persist();
    //     setCardDetails(prevCardDetails => {
    //         return {
    //             ...prevCardDetails,
    //             focus: e.target.name
    //         }
    //     });
    // }
    //
    // function handleInputChange(e) {
    //     const { name, value } = e.target;
    //
    //     setCardDetails(prevCardDetails => {
    //         return {
    //             ...prevCardDetails,
    //             [name]:value
    //         }
    //     });
    // }

    return (
        <Row id="PaymentForm" style={{marginTop:"20px"}}>
            <Col>
                <Form>
                    {/*<Form.Row>*/}
                    {/*    <Form.Group as={Col} controlId="formName">*/}
                    {/*        <Form.Control*/}
                    {/*            type="tel"*/}
                    {/*            name={"number"}*/}
                    {/*            placeholder="Card Number"*/}
                    {/*            onChange={handleInputChange}*/}
                    {/*            onFocus={handleInputFocus}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row>*/}
                    {/*    <Form.Group as={Col} controlId="formName">*/}
                    {/*        <Form.Control*/}
                    {/*            type="text"*/}
                    {/*            name={"name"}*/}
                    {/*            placeholder="Name"*/}
                    {/*            onChange={handleInputChange}*/}
                    {/*            onFocus={handleInputFocus}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</Form.Row>*/}
                    {/*<Form.Row>*/}
                    {/*    <Form.Group as={Col} controlId="formExpiry">*/}
                    {/*        <Form.Control*/}
                    {/*            type="text"*/}
                    {/*            name={"expiry"}*/}
                    {/*            placeholder="Expiry"*/}
                    {/*            onChange={handleInputChange}*/}
                    {/*            onFocus={handleInputFocus}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*    <Form.Group as={Col} controlId="formCVV">*/}
                    {/*        <Form.Control*/}
                    {/*            type="number"*/}
                    {/*            name={"cvc"}*/}
                    {/*            placeholder="CVC"*/}
                    {/*            onChange={handleInputChange}*/}
                    {/*            onFocus={handleInputFocus}*/}
                    {/*        />*/}
                    {/*    </Form.Group>*/}
                    {/*</Form.Row>*/}
                    <Form.Row>
                        <Form.Group style={{width:"300px"}}>
                            <CardElement />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group>
                            <Button variant="primary" type="submit" onClick={handleSubmit} disabled={!stripe}>
                                Pay
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Col>
            {/*<Col>*/}
            {/*    <Cards*/}
            {/*        cvc={cardDetails.cvc}*/}
            {/*        expiry={cardDetails.expiry}*/}
            {/*        focused={cardDetails.focus}*/}
            {/*        name={cardDetails.name}*/}
            {/*        number={cardDetails.number}*/}
            {/*    />*/}
            {/*</Col>*/}
        </Row>
    );
};

export default BookingForm;