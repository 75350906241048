import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import ItemList from "./ItemList";
import {UserContext} from "../utils/UserContext";

function MyUploads(props) {
    const {user, setUser} = useContext(UserContext);
    
    if (user == null) {
        return (
            <div>
                <p>You must be <a href={"/RegisterOrLogin"}>logged in</a> to access this page.</p>
            </div>
        )
    }
    
    return (
        <div>
            <h1>My Performances</h1>
            <ItemList type={"Performance"} order={"Created_at desc"} filter={"uploaderUser="+user.user.id} />
        </div>
    );
}

MyUploads.propTypes = {};
MyUploads.defaultProps = {};

export default MyUploads;
