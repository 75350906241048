import React from 'react';
import PropTypes from 'prop-types';

function NotFound(props) {
    return (
        <div>
            <img alt={"404 piano"} src={"./img/404.jpg"} style={{marginTop:'150px',marginLeft:'auto',marginRight:'auto',marginBottom:'20px',width:'300px'}} />
            <h1>404 Page Not Found</h1>
            <p>This page may have moved. Try typing the piece you're searching for into the search bar above.</p>
        </div>
    );
}

NotFound.propTypes = {};
NotFound.defaultProps = {};

export default NotFound;
