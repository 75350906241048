import React from 'react';
import PropTypes from 'prop-types';
import useAxios from "axios-hooks";
import {API_URL} from "../../utils/urls";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import SeatMap from "./SeatMap";

function EventAdmin(props) {
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: API_URL+'/events?start_gt='+props.timestamp+'&_sort=start:ASC',
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    
    return (
        <div>
            <Accordion defaultActiveKey="0">
                { data.map((event, index) => (
                        <Card key={index}>
                            <Accordion.Toggle as={Card.Header} eventKey="{{index}}">
                                {event.id} - {event.name}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="{{index}}">
                                <Card.Body>
                                    <SeatMap event={event} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                )) }
            </Accordion>
        </div>
    );
}

EventAdmin.propTypes = {};
EventAdmin.defaultProps = {};

export default EventAdmin;
