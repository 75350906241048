import React, {useEffect, useRef, useState} from 'react';
import Form from "react-bootstrap/Form";
import ReactDOM  from 'react-dom';

function TextInput(props) {
    const [isFocus,setIsFocus] = useState(false);
    const inputText = useRef(null);
    
    function handleFocus(event) {
        setIsFocus(true);
    }
    
    function handleBlur(event) {
        setIsFocus(false);
    }

    useEffect(() => {
        let node = ReactDOM.findDOMNode(inputText.current);
        node.addEventListener("focus",handleFocus,true);
        node.addEventListener("blur",handleBlur,true);
        node.addEventListener("input",props.onChange,true);
    },[inputText]);

    return (
        <Form.Group controlId='formBasic${props.placeholder}'>
            <Form.Control ref={inputText} type={props.type} placeholder={props.placeholder} onChange={props.onChange} />
            {(props.text && isFocus) &&
                <Form.Text className="text-muted">
                    {props.text}
                </Form.Text>
            }
        </Form.Group>
    );
}

TextInput.propTypes = {};
TextInput.defaultProps = {};

export default TextInput;