import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

function CheckoutReturn(props) {
    const [query,setQuery] = useState(null);
    
    useEffect(() => {
        // Check to see if this is a redirect back from Checkout
        const query = new URLSearchParams(window.location.search);      
        setQuery(query);
    },[]);
    
    if (query == null) return <div>Loading...</div>;
    
    if (query.get("success")) {
        return (
            <div>
                <h1>Confirmation</h1>
                <p>Your order has been processed successfully. You will receive an email to your registered address with your ticket attached.</p>
            </div>
        )
    }

    if (query.get("canceled")) {
        return (
            <div>
                <h1>Order Cancelled</h1>
                <p>Your order did not process correctly. Please go back and try again.</p>
            </div>
        )
    }
}

CheckoutReturn.propTypes = {};
CheckoutReturn.defaultProps = {};

export default CheckoutReturn;
