import React, {useEffect} from 'react';
import {string_to_slug} from "../utils/helpers";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";

function Item(props) {
    const history = useHistory();
    let thumbnailURL = '';
    let path;
    if (props.item.piece.composer != null) {
        path = "/"+string_to_slug(props.item.piece.composer.name)+"/"+string_to_slug(props.item.piece.name)+"/pe/"+props.item.id;
    } else {
        path = "/"+string_to_slug(props.item.piece.name)+"/pe/"+props.item.id;
    }
    
    const onItemClick = () => {
        let path;
        if (props.item.piece.composer != null) {
            path = "/"+string_to_slug(props.item.piece.composer.name)+"/"+string_to_slug(props.item.piece.name)+"/pe/"+props.item.id;
        } else {
            path = "/"+string_to_slug(props.item.piece.name)+"/pe/"+props.item.id;
        }
        history.push(path);
    };
    
    if (props.item.coverArt != null) {
        if (props.item.coverArt.formats == null) {
            thumbnailURL = props.item.coverArt.url;
        } else {
            thumbnailURL = props.item.coverArt.formats.thumbnail.url;
        }
    }
    
    if (props.size=="small") {
        return (
            <Card style={{ cursor: 'pointer', marginBottom: "2px" }} onClick={onItemClick} className={'small'}>
                <Row>
                    <div className={'itemImageContainer'}>
                        <Card.Img src={thumbnailURL} />
                    </div>
                    <Col>
                        <Card.Body>
                            <Card.Title><a href={path}>{props.item.piece.name}</a></Card.Title>
                            {(props.item.piece.composer != null) &&
                                <Card.Subtitle className="mb-2 text-muted">{props.item.piece.composer.name}</Card.Subtitle>
                            }
                            {/*<Card.Text>*/}
                            {/*    Some quick example text to build on the card title and make up the bulk of*/}
                            {/*    the card's content.*/}
                            {/*</Card.Text>*/}
                            {/*<Button variant="primary">Play</Button>*/}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        )
    }
    
    return (
            <Card style={{ cursor: 'pointer', marginBottom: "25px", width:'250px' }} onClick={onItemClick}>
                <Card.Img variant="top" src={thumbnailURL} />
                <Card.Body>
                    <Card.Title><a href={path}>{props.item.piece.name}</a></Card.Title>
                    { (props.item.piece.composer!=null) &&
                        <Card.Subtitle className="mb-2 text-muted">{props.item.piece.composer.name}</Card.Subtitle>
                    }
                    {/*<Card.Text>*/}
                    {/*    Some quick example text to build on the card title and make up the bulk of*/}
                    {/*    the card's content.*/}
                    {/*</Card.Text>*/}
                    {/*<Button variant="primary">Play</Button>*/}
                </Card.Body>
            </Card>
    );
}

Item.propTypes = {};
Item.defaultProps = {};

export default Item;
