import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';
import Toast from 'react-bootstrap/Toast';
import ToastElement from "./reusable/ToastElement";
import {UserContext} from "../utils/UserContext";
import {ToastContext} from "../utils/ToastContext";

function ToastDisplay(props) {
    const {toasts, addToast} = useContext(ToastContext);
    
    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'absolute',
                // minHeight: '200px',
                width: '100%',
                zIndex: '99999'
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                }}
            >
                {toasts}
            </div>
        </div>
    );
}

ToastDisplay.propTypes = {};
ToastDisplay.defaultProps = {};

export default ToastDisplay;
