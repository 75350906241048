import React from 'react';
import PropTypes from 'prop-types';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import EventHeader from "./reusable/headers/EventHeader";
import {string_to_slug} from "../utils/helpers";

function EventList(props) {
    let url = API_URL+'/events?start_gt='+props.timestamp+'&_sort=start:ASC';
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: url,
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;
    
    return (
        <Container style={{backgroundColor:"#FFFFFF"}}>
            <Row>
                <Col>
                    <h2>Upcoming Events</h2>
                    <ListGroup variant="flush">
                        { data.map((event) => (
                            <ListGroup.Item>
                                <a href={string_to_slug(event.name)+"/event/"+event.id}>
                                    <EventHeader event={event} />
                                </a>
                            </ListGroup.Item>
                        )) }
                    </ListGroup>
                </Col>
            </Row>
        </Container>
    );
}

EventList.propTypes = {};
EventList.defaultProps = {};

export default EventList;
