import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { parseArrayBuffer } from 'midi-json-parser';
import { create } from 'midi-player';
import Soundfont from 'soundfont-player';
import sequencer from 'heartbeat-sequencer';

function Midi2Json(props) {
    const [file, setFile] = useState(null);
    const [request, setRequest] = useState(null);
    const [midiEvents, setMidiEvents] = useState(null);
    const [jsonEvents, setJsonEvents] = useState(null);
    const [midiAccess, setMidiAccess] = useState(null);
    let ac = new AudioContext();
    const [piano, setPiano] = useState(null);
    const [performance, setPerformance] = useState(null);
    const textAreaRef = useRef();
    let song;
    
    function onEvent(event, time) {
        //console.log(event);
        if (event.noteOn) {
            let noteGain = event.noteOn.velocity / 127;
            piano.schedule(ac.currentTime, [
                { note: event.noteOn.noteNumber, time: time/1000, gain: noteGain, duration: 1 }
            ]);
        }
    }

    useEffect(() => {
        Soundfont.instrument(ac, 'acoustic_grand_piano', { soundfont: 'MusyngKite' }).then(function(pianoInstrument) {
            setPiano(pianoInstrument);
        });
        if (navigator.requestMIDIAccess) {
            //console.log('This browser supports WebMIDI!');
            getMidiAccess();
        } else {
            console.log('WebMIDI is not supported in this browser.');
        }
        sequencer.ready();
    },[]);

    async function getMidiAccess() {
        setMidiAccess(await navigator.requestMIDIAccess());
        console.log(midiAccess);
    }
    
    async function loadAndPlayJson(responseJson) {
        // console.log(midiAccess);
        // const midiOutput = Array.from(midiAccess.outputs.values())[0];
        // console.log(midiOutput);
        // const midiPlayer = create({ json, midiOutput, onEvent });
        // await midiPlayer.play();

        let midifile = {};
        let tempBPM;
        let timeEvents = [];
        midifile.base64 = '';
        midifile.numTracks = 0;
        let ppq = responseJson.division;
        midifile.tracks = [];
        responseJson.tracks.forEach(function(jsonTrack) {
            //console.log(jsonTrack);
            let part = sequencer.createPart();
            let track = sequencer.createTrack();
            const events = [];
            let tmpTicks = 0;
            let totalIgnoredEventCount = 0;
            jsonTrack.forEach(function(jsonEvent) {
                let eventType = 0;
                let d1 = 0;
                let d2 = 0;
                tmpTicks += jsonEvent.delta;
                if (jsonEvent.noteOn) {
                    eventType = 144;
                    d1 = jsonEvent.noteOn.noteNumber;
                    d2 = jsonEvent.noteOn.velocity;
                } else if (jsonEvent.noteOff) {
                    eventType = 128;
                    d1 = jsonEvent.noteOff.noteNumber;
                    d2 = jsonEvent.noteOff.velocity;
                } else if (jsonEvent.setTempo) {
                    tempBPM = (60000000/jsonEvent.setTempo.microsecondsPerQuarter);
                    //setBpm(tempBPM);
                    //eventType = 81;
                    //d1 = tempBPM;
                    //timeEvents.push(sequencer.createMidiEvent(tmpTicks, sequencer.TEMPO, tempBPM));
                } else if (jsonEvent.controlChange) {
                    eventType = 176;
                    d1 = jsonEvent.controlChange.type;
                    d2 = jsonEvent.controlChange.value;
                }
                if (eventType > 0) {
                    const midiEvent = sequencer.createMidiEvent([
                        tmpTicks,
                        eventType,
                        d1,
                        d2
                    ]);
                    events.push(midiEvent);
                } else {
                    totalIgnoredEventCount++;
                }
            });
            console.log("total ignored event count = "+totalIgnoredEventCount);
            console.log(events);
            setJsonEvents(events);
            if (events.length > 0) {
                part.addEvents(events);
                track.addPart(part);
                midifile.tracks.push(track);
                midifile.numTracks++;
            }
        });
        //midifile.autoSize = true;
        midifile.loaded = true;
        //midifile.useMetronome = true;
        midifile.ppq = ppq;
        midifile.bpm = tempBPM;
        let midiTrack = sequencer.createSong(midifile);
        console.log(midiTrack.events[501]);
        console.log(midiTrack.events[502]);
        midiTrack.addEventListener('event', 'type = NOTE_ON OR type = NOTE_OFF OR type = CONTROL_CHANGE', noteOnOffHandler);
        sequencer.addAssetPack({url: '/assets/heartbeat_asset_pack.json'}, () => {
            // set all tracks of the song to use 'piano'
            midiTrack.tracks.forEach(function(track){
                //console.log(track);
                track.setInstrument('piano');
                //track.setVolume(0);
            });
        },(midiTrack));
        midiTrack.play();
    }

    function uploadWithFormData(){
        console.log(file);
        let reader = new FileReader();
        reader.onload = function() {

            let arrayBuffer = this.result;
            parseArrayBuffer(arrayBuffer)
                .then((json) => {
                    // json is the JSON representation of the MIDI file.
                    console.log("-- json file loaded --");
                    console.log(json);
                    let performanceObject = {
                        midi: json,
                        hands: {}
                    };
                    setPerformance(performanceObject);
                    textAreaRef.current.value = JSON.stringify(performanceObject);
                });

        };
        reader.readAsArrayBuffer(file);
    }
    
    function startJsonPlayback() {
        loadAndPlayJson(performance.midi);
    }

    function createSong(){
        
    }
    
    useEffect(() => {
        if (performance != null) {
            loadAndPlayJson(performance.midi);
        }
    }, [performance]);
    
    const noteOnOffHandler = function(event) {
        if (event.command == 144) {
           //console.log("note on: "+event.data1);
           //console.log("event no: "+event.eventNumber);
        } else if (event.command == 128) {
            
        } else if (event.command == 176 && event.data1 == 64) {
            // if (event.data2 < 64) {
            //     pianoSynth.pedalUp();
            // } else {
            //     pianoSynth.pedalDown();
            // }
        }
    };
    
    useEffect(() => {
        if (request != null) {
            request.onload = () => {
                sequencer.createMidiFile({arraybuffer: request.result}).then(

                    function onFullfilled(midifile){
                        if(song){
                            sequencer.deleteSong(song);
                        }
                        song = sequencer.createSong(midifile);
                        
                        sequencer.addAssetPack({url: '/assets/heartbeat_asset_pack.json'}, () => {
                            // set all tracks of the song to use 'piano'
                            song.tracks.forEach(function(track){
                                //console.log(track);
                                track.setInstrument('piano');   
                                //track.setVolume(0);
                            });
                        },(song));
                        //console.log(song);
                        console.log("total no of events: "+song.events.length.toString());
                        setMidiEvents(song.events);
                        song.addEventListener('event', 'type = NOTE_ON OR type = NOTE_OFF OR type = CONTROL_CHANGE', noteOnOffHandler);
                        //song.play();
                        //console.log(sequencer.storage);
                    },

                    function onRejected(e){
                        console.log(e);
                    }
                );
            };
        }
    },[request]);
    
    useEffect(() => {
        if ((midiEvents != null) && (jsonEvents != null)) {
            console.log(midiEvents);
            console.log(jsonEvents);

            const results = midiEvents.filter(({ value: id1 }) => !jsonEvents.some(({ value: id2 }) => id2 === id1));

            console.log(results);
        }
    },[midiEvents,jsonEvents]);
    
    function startMidiPlayback() {
        let request = new FileReader();
        request.responseType = 'arraybuffer';
        //request.addEventListener('loadend', createSong, false);
        request.readAsArrayBuffer(file);
        setRequest(request);
    }
    
    function compare() {
        uploadWithFormData();
        startMidiPlayback();
    }
    
    return (
        <div>
            <label>
                MIDI File
                <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} />
                <input type="button" value="Upload" onClick={uploadWithFormData} />
                <input type="button" value="Play Json" onClick={startJsonPlayback} />
                <input type="button" value="Play Midi" onClick={startMidiPlayback} />
                <input type={"button"} value={"Compare"} onClick={compare} />
                <textarea ref={textAreaRef}>Json goes here...</textarea>
            </label>
        </div>
    );
}

Midi2Json.propTypes = {};
Midi2Json.defaultProps = {};

export default Midi2Json;
