import React, {useContext} from 'react';
import {UserContext} from "../utils/UserContext";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import SearchInput from "./SearchInput";

function GRNavBar() {
    const {user, setUser} = useContext(UserContext);

    const logout = () => {
        localStorage.removeItem('user');
        //this.setState({user:null});
        setUser(null);
    };
    
    return (
        <Navbar bg="dark" variant="dark" bsPrefix={"navbar"}>
            <Navbar.Brand href="/">
                Grand Reality
            </Navbar.Brand>
            <Form inline className="">
                <SearchInput />
                {/*<Button variant="outline-primary">Search</Button>*/}
            </Form>
            <Nav className="justify-content-end">
                {user &&
                <NavDropdown title={user.user.username} id="collasible-nav-dropdown">
                    <NavDropdown.Item href="/">Dashboard</NavDropdown.Item>
                    <NavDropdown.Item href="/upload-midi">Upload MIDI</NavDropdown.Item>
                    <NavDropdown.Item href="/my-uploads">My Uploads</NavDropdown.Item>
                    {/*<NavDropdown.Item href="#action/3.3">Stats</NavDropdown.Item>*/}
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
                </NavDropdown>
                }
                {!user &&
                <Nav.Item>
                    <Nav.Link href={"/RegisterOrLogin"}>Log in</Nav.Link>
                </Nav.Item>
                }
            </Nav>
        </Navbar>
    );
}

export default GRNavBar;
