import React, {useContext} from 'react';
import useAxios from 'axios-hooks'
import {API_URL} from "../utils/urls";
import {UserContext} from "../utils/UserContext";
import Item from "./Item";
import Container from "react-bootstrap/Container";
import CardColumns from "react-bootstrap/CardColumns";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


function ItemList(props) {
    let endpoint_url = API_URL+'/performances';
    if (props.filter != null) {
        endpoint_url += "?"+props.filter;
    }
    const {user, setUser} = useContext(UserContext);
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: endpoint_url,
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;

    const listItems = data.map((item) => 
        <Item key={item.id} item={item} size={props.size} />
    );
    //console.log(endpoint_url);
    
    if (listItems.length == 0) {
        return (
            <p>There are no items in this list.</p>
        )
    }
    
    if (props.size=="small") {
        return (
            <ul className={"searchResultsContainer"}>
                {listItems}
            </ul>
        );
    }

    return (
        <CardColumns>
            {listItems}
        </CardColumns>
    );
}

export default ItemList;