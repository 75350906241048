import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import {
    Link,
    useParams
} from "react-router-dom";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Moment from "react-moment";
import AddToCalendar from "@culturehq/add-to-calendar";
import '@culturehq/add-to-calendar/dist/styles.css';
import Footer from "./Footer";
import Socials from "./Socials";
import MetaTags from 'react-meta-tags';
import {getFromDB, postToDB, string_to_slug} from "../utils/helpers";
import BookingForm from "./BookingForm";
import {loadStripe} from "@stripe/stripe-js";
import ReactMarkdown from 'react-markdown'
import EventHeader from "./reusable/headers/EventHeader";
import EventDetailRow from "./reusable/EventDetailRow";
import {UserContext} from "../utils/UserContext";
import {ToastContext} from "../utils/ToastContext";
import ButtonSpinner from "./reusable/ButtonSpinner";

function Event(props) {
    const {user, setUser} = useContext(UserContext);
    const {toasts, addToast} = useContext(ToastContext);
    const stripePromise = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? loadStripe('pk_test_ZvbKpDonMLEVSth6KAsn1AE600LPXQxmhO') : loadStripe('pk_live_7h6iYIhSuxYUKknJR1mnyAAb00zZDc7AwT');
    
    let { id } = useParams();
    const [{ data, loading, error }, refetch] = useAxios(
        {
            url: API_URL+'/events/'+id,
            method: 'GET',
            //headers: {'Authorization': 'Bearer '+user.jwt}
        }
    );
    const [startTimeLocal, setStartTimeLocal] = useState(null);
    const [addToCalendarEvent, setAddToCalendarEvent] = useState(null);
    const [eventName,setEventName] = useState(null);
    const [dateTitle, setDateTitle] = useState(null);
    const [timeTitle, setTimeTitle] = useState(null);
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [userIsAttending, setUserIsAttending] = useState(false);
    //const [cardDetailsShowing, setCardDetailsShowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTicketCount, setAvailableTicketCount] = useState(0);
    
    let icon = { 'calendar-plus-o': 'left' };
    
    function setBackgroundImage(data) {
        props.setBackgroundStyle(data.cover.url);
        console.log("here");
    }
    
    async function handlePayButton(paymentMethod) {
        setPaymentMethod(paymentMethod);
        let order = {
            user: user.user.id,
            eventId: id,
            transactionAmount: data.price,
            ticketCount: 1,
            paymentMethodId: paymentMethod.id
        };
        let response = await postToDB(user, "orders", order);
        //console.log(response);
        addToast("Success!","You are now attending this event. Details, including your ticket, will be sent to your registered email address.");
    }

    function formatAMPM(date) {
        var hours = date.getHours();
        // let offset = date.getTimezoneOffset();
        // if (offset > 0) {
        //     hours += offset;
        // }
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + '' + ampm;
        return strTime;
    }

    async function handleBuyTicketSubmit() {
        setIsLoading(true);
        const stripe = await stripePromise;
        let order = {
            user: user.user.id,
            eventId: id,
            transactionAmount: data.price,
            ticketCount: 1
        };
        let response = await postToDB(user, "orders", order);
        console.log(response);
        
        if (data.price > 0) {
            // When the customer clicks on the button, redirect them to Checkout.
            const result = await stripe.redirectToCheckout({
                sessionId: response.sessionId,
            });
        } else if (response.completed === "true") {
            setIsLoading(false);
            setUserIsAttending(true);
            setAvailableTicketCount(prevValue => prevValue-1);
        }
        addToast("Success!","You are now attending this event. Details, including your ticket, will be sent to your registered email address.");
        
        //res.json({ id: session.id });
    };

    useEffect(() => {
        if (typeof data !== 'undefined') {
            setBackgroundImage(data);
            //console.log(data);
            let eventName = data.name+" | Live VR Piano Concert";
            let startTime = new Date(data.start);
            //console.log(startTime);
            let endTime = new Date(data.end);
            //endTime.setHours(startTime.getHours()+1);
            setEventName(eventName);
            //setStartTimeLocal(startTime);
            setAvailableTicketCount(data.availableTicketCount);
            // setAddToCalendarEvent({
            //     name: eventName,
            //     details: 'Click here for instructions on how to join: '+window.location,
            //     location: 'SideQuest Symphony Hall',
            //     startsAt: startTime.toISOString(),
            //     endsAt: endTime.toISOString()
            // });
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            setDateTitle(startTime.toLocaleDateString("en-UK", options));
            setTimeTitle(formatAMPM(startTime)+" - "+formatAMPM(endTime) + " " + Intl.DateTimeFormat().resolvedOptions().timeZone + " time");
            if (user!=null) {
                findUserTicket();
            }
        } else {
            //console.log(data);
        }
    }, [data]);
    
    async function findUserTicket() {
        let userTicket = await getFromDB(user, "tickets", {user: user.user.id, event: id});
        console.log(userTicket);
        if (userTicket.length > 0) {
            setUserIsAttending(true);
        }
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error!</p>;

    return (
        <div>
            <MetaTags>
                <title>{eventName}</title>
                <meta name="description" content="We're very excited to announce the SideQuest Symphony Hall's opening concert. Please join us for an unique opportunity to witness the first ever jazz piano performance in virtual reality." />
                <meta property="og:url"                content='{{window.location}}' />
                <meta property="og:type"               content="website" />
                <meta property="og:title"              content="{{eventName}}" />
                <meta property="og:description"        content="We're very excited to announce the SideQuest Symphony Hall's opening concert. Please join us for an unique opportunity to witness the first ever jazz piano performance in virtual reality." />
                <meta property="og:image"              content="{{data.cover.url}}" />
            </MetaTags>
            <Container className={"p-0"} style={{marginTop:"15px",backgroundColor:"#ffffff"}}>
                <Row className={"p-0"} noGutters={true}>
                    <Col className={"p-0"} md={6} style={{overflow:"hidden"}}>
                        <Image style={{width:"100%"}} src={data.cover.url} fluid />
                    </Col>
                    <Col style={{textAlign:"left",padding:"15px"}}>
                        <EventHeader event={data} />
                        <div className={"eventDetailsRow"}>
                            <EventDetailRow imgSrc={"/img/icons/map-placeholder.png"} upperTitle={"SideQuest Symphony Hall"} lowerTitle={<a href={"https://grandreality.app"}>Download Grand Reality now</a>} />
                            <EventDetailRow imgSrc={"/img/icons/baseline_calendar_today_black_18dp.png"} upperTitle={dateTitle} lowerTitle={timeTitle} />
                            <EventDetailRow imgSrc={"/img/icons/tickets.png"} upperTitle={(data.price > 0) ? "$"+data.price : "FREE"} lowerTitle={(availableTicketCount > 0) ? availableTicketCount+" Tickets Left" : "SOLD OUT"} />
                        </div>
                        {/*<p><Link to={"/"+string_to_slug(data.artist.name)+"/artist/"+data.artist.id}>{data.artist.name}</Link></p>*/}
                        {/*<p>*/}
                        {/*    <Moment*/}
                        {/*    utc*/}
                        {/*    local*/}
                        {/*    >*/}
                        {/*        {startTimeLocal}*/}
                        {/*    </Moment>*/}
                        {/*</p>*/}
                        {/*<AddToCalendar*/}
                        {/*    event={addToCalendarEvent}*/}
                        {/*/>*/}
                        {/*<p>At the SideQuest Symphony Hall (<a target={"_blank"} href={'https://sidequestvr.com/app/476/grand-reality-vr-piano-app'}>Download Grand Reality for free on SideQuest</a>)</p>*/}
                        {/*<p>Hosted by <a href={"/"+string_to_slug(data.host_user.username)+"/user/"+data.host_user.id}>{data.host_user.username}</a></p>*/}
                        {/*<Elements stripe={stripePromise}>*/}
                        {/*    <BookingForm event={data} />*/}
                        {/*</Elements>*/}
                    </Col>
                </Row>
            </Container>
            <Container style={{backgroundColor:"#FFFFFF"}}>
                <hr style={{marginTop:"0px"}} />
                <Row>
                    <Col md={5}>
                        <Button onClick={handleBuyTicketSubmit} size="lg" block disabled={userIsAttending || (data.availableTicketCount===0) || (user==null)}>
                            {isLoading === true &&
                            <ButtonSpinner />
                            }
                            {user == null &&
                            "Login or Register to Get a Ticket"
                            }
                            {(data.availableTicketCount === 0) && (!userIsAttending) && (user != null) &&
                                "SOLD OUT"
                            }
                            {userIsAttending &&
                                "Attending"
                            }
                            {(!userIsAttending && (data.price > 0) && (data.availableTicketCount > 0)) && (user != null) &&
                                "Buy Your Ticket Here"
                            }
                            {(!userIsAttending && (data.price === 0) && (data.availableTicketCount > 0)) && (user != null) &&
                                "Get FREE Ticket"
                            }
                        </Button>
                    </Col>
                    <Col>
                        <h5 style={{marginTop:"10px"}}>- OR -</h5>
                    </Col>
                    <Col md={5}>
                        <Button target={"_blank"} href={'https://www.patreon.com/GrandReality'} variant="warning" size="lg" block>Support Grand Reality on Patreon</Button>
                        <small className="text-muted" style={{display:"block"}}>Patreon supporters get free, priority tickets to all Grand Reality concerts.</small>
                    </Col>
                </Row>
                {/*{(data.price > 0 && cardDetailsShowing) &&*/}
                {/*<Row>*/}
                {/*    <Col>*/}
                {/*        <Elements stripe={stripePromise}>*/}
                {/*            <BookingForm setPaymentMethod={handlePayButton} />*/}
                {/*        </Elements>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*}*/}
                <hr />
                <Row>
                    <Col style={{textAlign:"left"}}>
                        <ReactMarkdown>{data.description}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>Please join <a target={"_blank"} href={"https://discord.com/invite/r7kRJfu"}>the Grand Reality discord channel</a> for the latest information on the ticket release schedule.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Socials />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

Event.propTypes = {};
Event.defaultProps = {};

export default Event;
