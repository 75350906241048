import React from 'react';
import PropTypes from 'prop-types';
import Spinner from "react-bootstrap/Spinner";

function ButtonSpinner(props) {
    return (
        <div>
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
            /> <br />
        </div>
    );
}

ButtonSpinner.propTypes = {};
ButtonSpinner.defaultProps = {};

export default ButtonSpinner;
