import React from 'react';
import PropTypes from 'prop-types';
import ItemList from "./ItemList";
import Footer from "./Footer";
import useAxios from "axios-hooks";
import {API_URL} from "../utils/urls";
import FeaturedEvent from "./FeaturedEvent";
import EventList from "./EventList";

function Home(props) {
    
    return (
        <div>
            <FeaturedEvent/>
            <br />
            <EventList timestamp={Date.now()} />
            {/*<ItemList type={"Performance"} order={"Created_at desc"} />*/}
            <Footer />
        </div>
    );
}

Home.propTypes = {};
Home.defaultProps = {};

export default Home;